export const SsrCarousel = () => import('../../node_modules/vue-ssr-carousel/index.js' /* webpackChunkName: "components/ssr-carousel" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/Footer.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/Header.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppSideMenu = () => import('../../components/app/SideMenu.vue' /* webpackChunkName: "components/app-side-menu" */).then(c => wrapFunctional(c.default || c))
export const AppUserNavbar = () => import('../../components/app/UserNavbar.vue' /* webpackChunkName: "components/app-user-navbar" */).then(c => wrapFunctional(c.default || c))
export const CoreAppDropdown = () => import('../../components/core/AppDropdown.vue' /* webpackChunkName: "components/core-app-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CoreBlogDate = () => import('../../components/core/BlogDate.vue' /* webpackChunkName: "components/core-blog-date" */).then(c => wrapFunctional(c.default || c))
export const CoreButton = () => import('../../components/core/Button.vue' /* webpackChunkName: "components/core-button" */).then(c => wrapFunctional(c.default || c))
export const CoreDetailsBox = () => import('../../components/core/DetailsBox.vue' /* webpackChunkName: "components/core-details-box" */).then(c => wrapFunctional(c.default || c))
export const CoreDropdown = () => import('../../components/core/Dropdown.vue' /* webpackChunkName: "components/core-dropdown" */).then(c => wrapFunctional(c.default || c))
export const CoreFaq = () => import('../../components/core/Faq.vue' /* webpackChunkName: "components/core-faq" */).then(c => wrapFunctional(c.default || c))
export const CoreLirPrice = () => import('../../components/core/LirPrice.vue' /* webpackChunkName: "components/core-lir-price" */).then(c => wrapFunctional(c.default || c))
export const CoreLoading = () => import('../../components/core/Loading.vue' /* webpackChunkName: "components/core-loading" */).then(c => wrapFunctional(c.default || c))
export const CoreMarkdown = () => import('../../components/core/Markdown.vue' /* webpackChunkName: "components/core-markdown" */).then(c => wrapFunctional(c.default || c))
export const CorePrice = () => import('../../components/core/Price.vue' /* webpackChunkName: "components/core-price" */).then(c => wrapFunctional(c.default || c))
export const CoreSvgIcon = () => import('../../components/core/SvgIcon.vue' /* webpackChunkName: "components/core-svg-icon" */).then(c => wrapFunctional(c.default || c))
export const DashboardCard = () => import('../../components/dashboard/Card.vue' /* webpackChunkName: "components/dashboard-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardQuickAccess = () => import('../../components/dashboard/QuickAccess.vue' /* webpackChunkName: "components/dashboard-quick-access" */).then(c => wrapFunctional(c.default || c))
export const PublicCapturePhoto = () => import('../../components/public/CapturePhoto.vue' /* webpackChunkName: "components/public-capture-photo" */).then(c => wrapFunctional(c.default || c))
export const PublicCaptureVideo = () => import('../../components/public/CaptureVideo.vue' /* webpackChunkName: "components/public-capture-video" */).then(c => wrapFunctional(c.default || c))
export const PublicCaptureVideoNew = () => import('../../components/public/CaptureVideoNew.vue' /* webpackChunkName: "components/public-capture-video-new" */).then(c => wrapFunctional(c.default || c))
export const PublicRowList = () => import('../../components/public/RowList.vue' /* webpackChunkName: "components/public-row-list" */).then(c => wrapFunctional(c.default || c))
export const PublicStepper = () => import('../../components/public/Stepper.vue' /* webpackChunkName: "components/public-stepper" */).then(c => wrapFunctional(c.default || c))
export const PublicTabBtnC = () => import('../../components/public/TabBtnC.vue' /* webpackChunkName: "components/public-tab-btn-c" */).then(c => wrapFunctional(c.default || c))
export const PublicTabBtns = () => import('../../components/public/TabBtns.vue' /* webpackChunkName: "components/public-tab-btns" */).then(c => wrapFunctional(c.default || c))
export const PublicTabBtnsFull = () => import('../../components/public/TabBtnsFull.vue' /* webpackChunkName: "components/public-tab-btns-full" */).then(c => wrapFunctional(c.default || c))
export const AppModalFactor = () => import('../../components/app/modal/Factor.vue' /* webpackChunkName: "components/app-modal-factor" */).then(c => wrapFunctional(c.default || c))
export const AppPopupAddLirAccount = () => import('../../components/app/popup/AddLirAccount.vue' /* webpackChunkName: "components/app-popup-add-lir-account" */).then(c => wrapFunctional(c.default || c))
export const AppPopupLayout = () => import('../../components/app/popup/Layout.vue' /* webpackChunkName: "components/app-popup-layout" */).then(c => wrapFunctional(c.default || c))
export const AppPopupMessage = () => import('../../components/app/popup/Message.vue' /* webpackChunkName: "components/app-popup-message" */).then(c => wrapFunctional(c.default || c))
export const AppPopupOverlay = () => import('../../components/app/popup/Overlay.vue' /* webpackChunkName: "components/app-popup-overlay" */).then(c => wrapFunctional(c.default || c))
export const AppPopupWebApp = () => import('../../components/app/popup/WebApp.vue' /* webpackChunkName: "components/app-popup-web-app" */).then(c => wrapFunctional(c.default || c))
export const CoreInputCheckBox = () => import('../../components/core/input/CheckBox.vue' /* webpackChunkName: "components/core-input-check-box" */).then(c => wrapFunctional(c.default || c))
export const CoreInputCodeField = () => import('../../components/core/input/CodeField.vue' /* webpackChunkName: "components/core-input-code-field" */).then(c => wrapFunctional(c.default || c))
export const CoreInputCustomInput = () => import('../../components/core/input/CustomInput.vue' /* webpackChunkName: "components/core-input-custom-input" */).then(c => wrapFunctional(c.default || c))
export const CoreInputFileField = () => import('../../components/core/input/FileField.vue' /* webpackChunkName: "components/core-input-file-field" */).then(c => wrapFunctional(c.default || c))
export const CoreInputInfoField = () => import('../../components/core/input/InfoField.vue' /* webpackChunkName: "components/core-input-info-field" */).then(c => wrapFunctional(c.default || c))
export const CoreInputNewInfoField = () => import('../../components/core/input/NewInfoField.vue' /* webpackChunkName: "components/core-input-new-info-field" */).then(c => wrapFunctional(c.default || c))
export const CoreInputNumberField = () => import('../../components/core/input/NumberField.vue' /* webpackChunkName: "components/core-input-number-field" */).then(c => wrapFunctional(c.default || c))
export const CoreInputPasswordField = () => import('../../components/core/input/PasswordField.vue' /* webpackChunkName: "components/core-input-password-field" */).then(c => wrapFunctional(c.default || c))
export const CoreInputRange = () => import('../../components/core/input/Range.vue' /* webpackChunkName: "components/core-input-range" */).then(c => wrapFunctional(c.default || c))
export const CoreInputTextField = () => import('../../components/core/input/TextField.vue' /* webpackChunkName: "components/core-input-text-field" */).then(c => wrapFunctional(c.default || c))
export const CoreRadioSimple = () => import('../../components/core/radio/Simple.vue' /* webpackChunkName: "components/core-radio-simple" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectCard = () => import('../../components/core/select/Card.vue' /* webpackChunkName: "components/core-select-card" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectCreditCard = () => import('../../components/core/select/CreditCard.vue' /* webpackChunkName: "components/core-select-credit-card" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectDay = () => import('../../components/core/select/Day.vue' /* webpackChunkName: "components/core-select-day" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectRadio = () => import('../../components/core/select/Radio.vue' /* webpackChunkName: "components/core-select-radio" */).then(c => wrapFunctional(c.default || c))
export const CoreSelectSimple = () => import('../../components/core/select/Simple.vue' /* webpackChunkName: "components/core-select-simple" */).then(c => wrapFunctional(c.default || c))
export const PublicCreditCardRial = () => import('../../components/public/credit-card/Rial.vue' /* webpackChunkName: "components/public-credit-card-rial" */).then(c => wrapFunctional(c.default || c))
export const PublicCreditCardTether = () => import('../../components/public/credit-card/Tether.vue' /* webpackChunkName: "components/public-credit-card-tether" */).then(c => wrapFunctional(c.default || c))
export const PublicLirForm = () => import('../../components/public/lir/Form.vue' /* webpackChunkName: "components/public-lir-form" */).then(c => wrapFunctional(c.default || c))
export const PublicLirIcon = () => import('../../components/public/lir/Icon.vue' /* webpackChunkName: "components/public-lir-icon" */).then(c => wrapFunctional(c.default || c))
export const PublicLirInfo = () => import('../../components/public/lir/Info.vue' /* webpackChunkName: "components/public-lir-info" */).then(c => wrapFunctional(c.default || c))
export const PublicSliderBlog = () => import('../../components/public/slider/Blog.vue' /* webpackChunkName: "components/public-slider-blog" */).then(c => wrapFunctional(c.default || c))
export const PublicSliderBlogPosts = () => import('../../components/public/slider/BlogPosts.vue' /* webpackChunkName: "components/public-slider-blog-posts" */).then(c => wrapFunctional(c.default || c))
export const PublicSliderComments = () => import('../../components/public/slider/Comments.vue' /* webpackChunkName: "components/public-slider-comments" */).then(c => wrapFunctional(c.default || c))
export const PublicTetherBuy = () => import('../../components/public/tether/Buy.vue' /* webpackChunkName: "components/public-tether-buy" */).then(c => wrapFunctional(c.default || c))
export const PublicTetherForm = () => import('../../components/public/tether/Form.vue' /* webpackChunkName: "components/public-tether-form" */).then(c => wrapFunctional(c.default || c))
export const PublicTetherFormWide = () => import('../../components/public/tether/FormWide.vue' /* webpackChunkName: "components/public-tether-form-wide" */).then(c => wrapFunctional(c.default || c))
export const PublicTetherIcon = () => import('../../components/public/tether/Icon.vue' /* webpackChunkName: "components/public-tether-icon" */).then(c => wrapFunctional(c.default || c))
export const PublicTetherInfo = () => import('../../components/public/tether/Info.vue' /* webpackChunkName: "components/public-tether-info" */).then(c => wrapFunctional(c.default || c))
export const PublicTetherNewInfo = () => import('../../components/public/tether/NewInfo.vue' /* webpackChunkName: "components/public-tether-new-info" */).then(c => wrapFunctional(c.default || c))
export const PublicTetherSell = () => import('../../components/public/tether/Sell.vue' /* webpackChunkName: "components/public-tether-sell" */).then(c => wrapFunctional(c.default || c))
export const AppPopupDashboardRialDeposit = () => import('../../components/app/popup/dashboard/RialDeposit.vue' /* webpackChunkName: "components/app-popup-dashboard-rial-deposit" */).then(c => wrapFunctional(c.default || c))
export const AppPopupDashboardRialToTether = () => import('../../components/app/popup/dashboard/RialToTether.vue' /* webpackChunkName: "components/app-popup-dashboard-rial-to-tether" */).then(c => wrapFunctional(c.default || c))
export const AppPopupDashboardRialWithdraw = () => import('../../components/app/popup/dashboard/RialWithdraw.vue' /* webpackChunkName: "components/app-popup-dashboard-rial-withdraw" */).then(c => wrapFunctional(c.default || c))
export const AppPopupDashboardTetherDeposit = () => import('../../components/app/popup/dashboard/TetherDeposit.vue' /* webpackChunkName: "components/app-popup-dashboard-tether-deposit" */).then(c => wrapFunctional(c.default || c))
export const AppPopupDashboardTetherToRial = () => import('../../components/app/popup/dashboard/TetherToRial.vue' /* webpackChunkName: "components/app-popup-dashboard-tether-to-rial" */).then(c => wrapFunctional(c.default || c))
export const AppPopupDashboardTetherWithdraw = () => import('../../components/app/popup/dashboard/TetherWithdraw.vue' /* webpackChunkName: "components/app-popup-dashboard-tether-withdraw" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
